import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Banner_Card from '../Common_Component/Banner_Card/Banner_Card';
import './AllProductList.css'
import { Context } from '../../utils/context';
import { useNavigate } from 'react-router-dom';
import Heart_Component from '../Common_Component/Heart_Component/Heart_Component';
import Product_Details_Div from '../Common_Component/Product_Details_Div/Product_Details_Div';
import { MaxPrice, Products } from '../../utils/apis/Product/Product';
import { Brands } from '../../utils/apis/master/Master';
const AllProductList = ({ sliderImages, categoryActive }) => {

    const priceRanges = [
        { label: "₹500 & Under", min: 0, max: 500 },
        { label: "₹500 - ₹999", min: 500, max: 999 },
        { label: "₹1,000 - ₹1,999", min: 1000, max: 1999 },
        { label: "₹2,000 - ₹5000", min: 2000, max: 5000 },
        { label: "₹5000 & Over", min: 5000, max: Infinity }
    ];
    const brand = [
        { label: "acer" },
        { label: "apple" },
        { label: "asus" },
        { label: "dell" },
        { label: "intel" }
    ];
    const { IMG_URL, sellerId,type } = useContext(Context);

    const navigate = useNavigate();

    const [products, setProduct] = useState([]);
    const getproducts = async () => {
        const res = await Products("", "", "", sellerId);
        if (res?.success) {
            setProduct(res?.data);
        }
    }

    
    const [brands, setBrand] = useState([]);
    const getbrands = async () => {
        const res = await Brands(sellerId,type);
        if (res?.success) {
            setBrand(res?.data);
        }
    }

    const [priceSet, setPrice] = useState([]);
    const getprice = async () => {
        const res = await MaxPrice(sellerId,type);
        if (res?.success) {
            let min = res?.data?.minPrice;
            let max = res?.data?.maxPrice;

            // priceSet.push({minPrice:min, maxPrice:"", label: min + "& Under"});

            

        }
    }



    useEffect(() => {
        getproducts();
        getbrands();
        getprice();
    }, []);
    return (
        <>
            <section className='ProductCategory'>
                <Container>
                    <Row>
                        <Col lg={3}>
                            <div className='LeftSidesec'>
                                <ul>
                                    <h3>Price</h3>
                                    {priceRanges?.map((range, index) => (
                                        <li key={index}>
                                            <Form.Check label={range?.label} />
                                        </li>
                                    ))}

                                </ul>
                            </div>

                            <div className='LeftSidesec'>
                                <ul>
                                    <h3>Brands</h3>
                                    {brands?.map((brandrange, index) => (
                                        <li key={index}>
                                            <Form.Check label={brandrange?.name} />
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <Row>
                                {products?.data?.map((value, index) => (
                                    <Col xs={12} sm={6} md={6} lg={4}>
                                        <div>
                                            <Banner_Card

                                                imgClassName="Tab_Slider_Img"
                                                // detailsLink={slide.detailsLink}
                                                imgSource={
                                                    IMG_URL +
                                                    value?.thumbnail
                                                }
                                                heartClassName={"Heart_Like"}
                                                HeartLikeComponent={<Heart_Component />}
                                                ProductDetailsClassName={"Product_Details"}
                                                ProductDetailsSection={
                                                    <Product_Details_Div
                                                        // product={slide}
                                                        // ColorCartHolder={<Color_Cart_Holder />}
                                                        subheading={value?.product_title}
                                                        spanText={value?.product_generic_name}
                                                        // productDescription={slide?.product_title}
                                                        Price={value?.selling_price}
                                                        subPrice={value?.mrp}
                                                    />
                                                }
                                            />
                                        </div>
                                    </Col>
                                ))}


                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AllProductList