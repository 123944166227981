import React,{useContext, useEffect, useState} from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";

import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

import { Context } from "../../utils/context";
import { GetAppSetup } from "../../utils/apis/master/Master";


function Footer() {

  const { cartCount, signin, setSignin, sellerId, IMG_URL } = useContext(Context);

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }
  }

  useEffect(() => {
    getAppSetup();
  }, [sellerId]);
  return (
    <>
      <section className="footer-section" style={{
        background: appSetup?.footer_color
      }}>
        <Container fluid>
          <Container>
            <div className=" footermains pt-4 pb-3">
              <Row>
                <Col lg={4} md={6} sm={6}>
                  <div className="footer-col">
                    <div className="toptext  mb-lg-5">
                      <Link to={"/"}>
                        <img
                          src={
                            IMG_URL +
                            appSetup?.image
                          }
                          className="footer-logos"
                          alt="..."
                        />
                      </Link>
                    </div>
                    <div className="footerliks">
                      <Link to={"/"}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>HOME</p>
                      </Link>
                      <Link to={"/about-us"}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>ABOUT US</p>
                      </Link>
                      <Link to={"/contact-us"}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>CONTACT US</p>
                      </Link>
                      <Link to={"/blog"}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>BLOGS</p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="footer-col ">
                    <div className="toptext  mb-lg-5">
                      <h4 className="follows"  style={{
                                color:appSetup?.footer_font_color
                              }}>{appSetup?.email}</h4>
                    </div>
                    <div className="footerliks">
                      <Link to={""}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>PRIVACY POLICY</p>
                      </Link>
                      <Link to={""}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>TERMS OF USE</p>
                      </Link>
                      <Link to={""}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>RETURN POLICY</p>
                      </Link>
                      <Link to={"/faq"}>
                        <p className="linktxt"  style={{
                                color:appSetup?.footer_font_color
                              }}>FAQs</p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="footer-col">
                    <div className="toptext   mb-lg-5">
                      <h4 className="follows follow_link"  style={{
                                color:appSetup?.footer_font_color
                              }}>follow us on</h4>
                      <Link to={"https://www.facebook.com/"} target="blank">
                        <div className="footericnss"  style={{
                                color:appSetup?.footer_font_color
                              }}>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </div>
                      </Link>

                      <Link to={"https://in.linkedin.com/"} target="blank">
                        {" "}
                        <div className="footericnss"  style={{
                                color:appSetup?.footer_font_color
                              }}>
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </div>
                      </Link>
                      <Link to={"https://twitter.com/"} target="blank">
                        <div className="footericnss"  style={{
                                color:appSetup?.footer_font_color
                              }}>
                          <FontAwesomeIcon icon={faTwitter} />
                        </div>
                      </Link>
                      <Link to={"https://www.youtube.com/"} target="blank">
                        <div className="footericnss"  style={{
                                color:appSetup?.footer_font_color
                              }}>
                          <FontAwesomeIcon icon={faYoutube} />
                        </div>
                      </Link>
                    </div>
                    <div className="footerliks">
                      <div className="addresdetails"style={{
                                color:appSetup?.footer_font_color
                              }}>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="addresicns"
                        />
                        <p className="linktxt "  style={{
                                color:appSetup?.footer_font_color
                              }}>
                          {appSetup?.address}
                        </p>
                      </div>
                      <div className="addresdetails "style={{
                                color:appSetup?.footer_font_color
                              }}>
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="addresicns"
                        />
                        <p className="linktxt "  style={{
                                color:appSetup?.footer_font_color
                              }}>{appSetup?.contact_no}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="bottomtext">
              <div className="botright d-flex align-items-center">
                <FontAwesomeIcon icon={faCircleCheck} className="checkss" />
                <p className="copyright"  style={{
                                color:appSetup?.footer_font_color
                              }}>
                  VERIFIED BY <span className="coprightspan"> NETPURTI</span>
                </p>
              </div>
              <p className="copyright"  style={{
                                color:appSetup?.footer_font_color
                              }}>
                Copyright ©2024 All Rights Reserved. Developed by{" "}
                <Link>
                  <span>Profcyma.</span>
                </Link>
              </p>
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
}

export default Footer;
