import { React, useContext, useEffect, useState } from "react";
import ProductCart from "./ProductCart/ProductCart";
import SimilarPatterns from "./SimilarPatterns/SimilarPatterns";
import Reviews from "./Reviews/Reviews";
import Morebrands from "./Morebrands/Morebrands";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Context } from "../../utils/context";
import { getProductDetail } from "../../utils/apis/Product/Product";
import Cookies from 'js-cookie';
const ProductDescription = () => {
  const { id } = useParams();

  const { sellerId, IMG_URL, sellerDomain } = useContext(Context);

  const [category, setCategory] = useState("");




  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const atid = queryParams.get('atid');
  const a_id = queryParams.get('a_id');

  console.log(atid + " " + a_id, 'atid');

  useEffect(() => {
    if (a_id !== null) {
      Cookies.set('a_id', JSON.stringify({ a_id: a_id, p_id: id }), { expires: 1 });
    }
  }, [atid, a_id]);

  return (
    <>
      <ProductCart
        id={id}
        sellerId={sellerId}
        IMG_URL={IMG_URL}
        setCategory={setCategory}
        category={category}
      />
      <Reviews id={id} sellerId={sellerId} domain_id={sellerDomain} />
      <Morebrands />
      <SimilarPatterns
        heading={"Similar Patterns for you"}
        category={category}
      />
      {/* <SimilarPatterns heading={"Crafted for you"} /> */}
    </>
  );
};

export default ProductDescription;
