import React from 'react'
import "./Heading_Holder.css"

function Heading_Holder({headingText, textAlign}) {


  return (
   <>
    <div className="Heading_Holder" >
        <h3 className='heading' >{headingText}</h3>
    </div>
   </>
  )
}

export default Heading_Holder