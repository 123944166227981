import { React, useState, useRef, useEffect, useContext } from "react";
// import "./B2bSellerDetail.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "./ProductCart.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCartShopping,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import Heart_Component from "../../Common_Component/Heart_Component/Heart_Component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadcrumComponent from "../../Common_Component/BreadcrumComponent/BreadcrumComponent";
import { useForm } from "react-hook-form";
import { getProductDetail } from "../../../utils/apis/Product/Product";
import {
  AddToCart,
  RemoveToCart,
  getCartCheck,
} from "../../../utils/apis/addToCart/addToCart";

import { Context } from "../../../utils/context";
import Success_Modalss from "../../Common_Component/Success_Modal/Success_Modalss";
import Successfull_Modal from "../../Common_Component/Successfull_Modal/Successfull_Modal";

const ProductCart = ({ id, sellerId, IMG_URL, category, setCategory }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [swiperInstancemobile, setSwiperInstancemobile] = useState(null);
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);
  const [showAddedToCart, setShowAddedToCart] = useState(false);

  const { signin, sellerDomain } = useContext(Context);
  const navigate = useNavigate();
  const handleAddToCart = async (type = null) => {
    const data = {
      product_id: id,
      seller_id: sellerId,
      domain_id: sellerDomain,
      s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
      s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
      s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
    };

    await AddToCart(data);
    setShowAddedToCart(true);
    getCartCheckProduct(sellerDomain);

    setTimeout(() => {
      setShowAddedToCart(false);
    }, 3000);
  };

  const BuyNowClick = async () => {
    if (signin) {
      const data = {
        product_id: id,
        seller_id: sellerId,
        domian_id: sellerDomain,
        s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
        s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
        s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
      };

      if (!checkExits) {
        await AddToCart(data);
        setShowAddedToCart(true);
        getCartCheckProduct();
      }
      navigate("/product-cart-inner");
    } else {
      navigate("/login");
    }

  };

  const handleRemoveFromCart = async () => {
    const res = await RemoveToCart(checkExits);
    getCartCheckProduct(sellerDomain);
  };

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleSliderClick = (direction) => {
    if (swiperInstancenew) {
      if (direction === "prev") {
        swiperInstancenew.slidePrev();
      } else if (direction === "next") {
        swiperInstancenew.slideNext();
      }
    }
  };

  const handleSliderClickMobile = (direction) => {
    if (swiperInstancemobile) {
      if (direction === "prev") {
        swiperInstancemobile.slidePrev();
      } else if (direction === "next") {
        swiperInstancemobile.slideNext();
      }
    }
  };

  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "New Arrivals", link: `/our_products_page/${category} ` },
    { text: "Formal Shits", link: "", className: "active" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState([]);

  const [singleVariant, setSingleVariant] = useState(false);
  const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
  const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

  const [data, setData] = useState({});
  const [checkExits, setCheckExits] = useState(false);

  const getProductData = async (id) => {
    {
      const res = await getProductDetail(id, sellerId);
      if (res?.success) {
        setData(res.data);
        setCategory(res.data?.s_category_id);

        if (res?.data?.s_product_variant?.variant_type === "Single") {
          setData(res.data);
          setValue("product_title", res.data?.product_title);
          setValue(
            "customization",
            res.data?.s_product_customization?.s_customization?.name
          );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_product_variant_attribute_details
          );

          await setValue('activeVariant', res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.id);

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_product_variant_attribute_details?.[0]?.sku_id
          );

          setValue(
            "s_p_v_a_d_id",
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_product_variant_attribute_details?.[0]?.id
          );

          setValue(
            "mrp",
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_product_variant_attribute_details?.[0]
              ?.s_p_v_a_d_price_a_stock?.mrp
          );
          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_product_variant_attribute_details?.[0]
              ?.s_p_v_a_d_price_a_stock?.selling_price
          );

          setSingleVariant(true);
          setImages(
            res.data?.s_product_variant?.s_product_variant_attribute
              ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
          );
        } else if (res?.data?.s_product_variant?.variant_type === "Multiple") {
          if (
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
              ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
              ?.s_p_v_a_one_d_a_d_as.length == 0
          ) {
            setData(res.data);
            setValue("product_title", res.data?.product_title);
            setValue(
              "customization",
              res.data?.s_product_customization?.s_customization?.name
            );
            setValue(
              "variant",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
            );
            setValue(
              "variant_details",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
            );

            setValue(
              "variant_one",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
            );

            setValue(
              "variant_one_details",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
            );

            await setValue('activeVariant', res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.id);

            await setValue('activeSubVariant', res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id);

            setValue(
              "sku_id",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
            );
            setValue(
              "s_p_v_a_one_d_a_d_id",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.id
            );
            setValue(
              "mrp",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
            );
            setValue(
              "selling_price",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d
                ?.selling_price
            );
            setMultipleVariantTwo(true);
            setImages(
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_a_d_images
            );
          } else {
            setData(res.data);
            setValue("product_title", res.data?.product_title);
            setValue(
              "customization",
              res.data?.s_product_customization?.s_customization?.name
            );
            setValue(
              "variant",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
            );
            setValue(
              "variant_details",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
            );

            setValue(
              "variant_one",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
            );

            setValue(
              "variant_one_details",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
            );

            setValue(
              "variant_two",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_attribute?.name
            );

            setValue(
              "variant_two_details",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_p_v_a_one_d_a_d_a_ds
            );

            setValue(
              "sku_id",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
            );
            setValue(
              "s_p_v_a_o_d_a_d_a_d_id",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
            );
            setValue(
              "mrp",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
            );

            setValue(
              "selling_price",
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d
                ?.selling_price
            );

            setImages(
              res.data?.s_product_variant?.s_p_v_a_ones?.[0]
                ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
                ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_a_d_images
            );
            setMultipleVariantThree(true);
          }
        }
      }
    }
  };

  const getCartCheckProduct = async (sellerDomain) => {
    const res = await getCartCheck(id, sellerId, sellerDomain);
    if (res?.success) {
      setCheckExits(res.data.id);
    } else {
      setCheckExits(res?.success);
    }
  };

  const onChangeVariant = async (data, type = "Single", level = "1") => {
    if (type === "Single" && level === "1") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
      setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
      setValue("s_p_v_a_d_id", data?.id);
    } else if (type === "Multiple" && level === "2") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
      setValue("s_p_v_a_one_d_a_d_id", data?.id);
    } else if (type === "Multiple" && level === "3") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
      setValue("s_p_v_a_o_d_a_d_a_d_id", data?.id);
    }
  };

  const swiperRef = useRef(null);

  const handleClickSlide = (index) => {
    console.log(swiperRef.current.swiper);
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    if (id) {
      getProductData(id);
      getCartCheckProduct(sellerDomain);
    }
  }, [id]);

  const [shareModal, setShareModal] = useState(false);
  const shareHandle = async () => {
    setShareModal(true);

    setTimeout(() => {
      setShareModal(false);
    }, 2000);
  }
  const location = useLocation();

  return (
    <>
      {/* B2bSeller Section Started */}

      <section className="B2b-Seller-Detail">
        <div className="Background-image">
          <Container>
            <div className="mb-4">
              <BreadcrumComponent items={breadcrumbItems} />
            </div>

            <Row className="product-content">
              <Col xxl={5} xl={5} lg={5} md={5}>
                <div className="main">
                  <div className="sidebar-swiper">
                    <Swiper
                      direction={"vertical"}
                      slidesPerView={4}
                      spaceBetween={10}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 4,
                          spaceBetween: 60,
                        },
                        380: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        485: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        575: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },

                        768: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 4,
                          spaceBetween: 80,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1200: {
                          slidesPerView: 4,
                          spaceBetween: 80,
                        },
                        1440: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        2000: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                    >
                      {images?.map((item, index) => (
                        <SwiperSlide>
                          <div className="gallery-imgs" onClick={() => handleClickSlide(index)}>
                            <img
                              src={IMG_URL + item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      >
                        <FontAwesomeIcon icon={faCaretUp} />
                      </div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      >
                        <FontAwesomeIcon icon={faCaretDown} />
                      </div>
                    </div>
                  </div>

                  {/* this slider for mobile view started*/}
                  <div className="sidebar-swiper-mobile-view">
                    <Swiper

                      slidesPerView={"auto"}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        320: {
                          slidesPerView: 6,
                          spaceBetween: 10,
                        },
                        485: {
                          slidesPerView: 7,
                          spaceBetween: 10,
                        },
                        575: {
                          slidesPerView: 7,
                          spaceBetween: 20,
                        },

                        768: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 5,
                          spaceBetween: 80,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        1200: {
                          slidesPerView: 4,
                          spaceBetween: 80,
                        },
                        1440: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                        2000: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                    >
                      {images?.map((item, index) => (
                        <SwiperSlide>
                          <div className="gallery-imgs" onClick={() => handleClickSlide(index)}>
                            <img
                              src={IMG_URL + item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleSliderClickMobile("prev")}
                      >
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </div>
                      <div
                        className="next-btn"
                        onClick={() => handleSliderClickMobile("next")}
                      >
                        <FontAwesomeIcon icon={faCaretRight} />
                      </div>
                    </div>
                  </div>
                  {/* this slider for mobile view End*/}
                  <div className="background-product-detail ">
                    <div className="product-detail">
                      <Swiper
                        ref={swiperRef}
                        navigation={true}
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={10}
                        className="mySwiper"
                        onSwiper={(swiper) => setSwiperInstancenew(swiper)}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                          },
                          320: {
                            slidesPerView: 1,
                          },
                          485: {
                            slidesPerView: 1,
                          },
                          575: {
                            slidesPerView: 1,
                          },

                          768: {
                            slidesPerView: 1,
                          },
                          992: {
                            slidesPerView: 1,
                          },
                          1024: {
                            slidesPerView: 1,
                          },
                          1200: {
                            slidesPerView: 1,
                          },
                          1440: {
                            slidesPerView: 1,
                          },
                          2000: {
                            slidesPerView: 1,
                          },
                        }}
                      >
                        {images?.map((item, index) => (
                          <SwiperSlide key={index}>
                            <div className="onImageHeartsec">
                              {/* <Link to={item.baseLink}> */}
                              <img
                                className="product-img"
                                src={IMG_URL + item.image}
                                alt="Selected"
                              />
                              {/* </Link> */}
                              <div
                                className={`Overlay_text_holder ${showAddedToCart ? "show" : ""
                                  }`}
                              >
                                <div className="Overlay_text">
                                  <p>Item added to cart successfully!</p>
                                </div>
                              </div>

                              <div className="productheartIcon">
                                <Heart_Component id={id} />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className="silder-btn">
                        <div
                          className="back-btn"
                          onClick={() => handleSliderClick("prev")}
                        >
                          <FontAwesomeIcon icon={faCaretLeft} />
                        </div>
                        <div
                          className="next-btn"
                          onClick={() => handleSliderClick("next")}
                        >
                          <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={7} xl={7} lg={7} md={7}>
                <div className="product-information">
                  <div className="productHead">
                    <div className="heading-share">
                      <div>
                        <h1 className="subheading">
                          {getValues("product_title")} -{" "}
                          <span className="subText">{getValues("sku_id")}</span>{" "}
                        </h1>
                      </div>

                      <div className="share-btn">
                        <CopyToClipboard text={window.location.href}>
                          <FontAwesomeIcon
                            className="share"
                            icon="fa-solid fa-share"
                            onClick={shareHandle}
                          />
                        </CopyToClipboard>
                      </div>

                      <Successfull_Modal show={shareModal} message={'Copied to Clipboard'} onHide={() => setShareModal(false)} />

                      {/* <div className="share-btn">
                        <Link
                          to={"/product-cart-inner"}
                          onClick={() => {
                            setCopyStatus(true);
                          }}
                          onClick={copyToClipboard}
                        >
                          <FontAwesomeIcon
                            className="share"
                            icon="fa-solid fa-share"
                          />
                        </Link>
                      </div>
                      {copyStatus && <h6>Copied</h6>} */}
                    </div>

                    <p className="">
                      {data?.s_product_information?.meta_description}
                    </p>
                  </div>

                  <div className="priceSection">
                    <p>₹ {getValues("selling_price")}</p>
                    <p>₹ {getValues("mrp")}</p>
                  </div>

                  <div className="Inclusivetxt">
                    <p>Inclusive of all taxes</p>
                  </div>

                  {singleVariant && (
                    <>
                      <p className="product-id mt-2">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="standard-section">
                        {getValues("variant_details")?.map((item, index) => (
                          <Button
                            variant="standard"
                            className={getValues("activeVariant") === item?.id ? "active" : ''}
                            key={item?.id ?? index}
                            onClick={async () => {
                              await setValue('activeVariant', item?.id);
                              await onChangeVariant(item, "Single", "1");
                              await setImages(item?.s_p_v_a_d_images);
                            }}
                          >
                            {item?.s_sub_attribute?.name ?? "Unknown"}
                          </Button>
                        ))}
                      </div>
                      {/* <p className="product-id">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="main-section-tabs">
                        {getValues("variant_details")?.map((item, index) => (
                          <div className="background-color-sofa" key={index}>
                            {" "}
                            <img
                              src={IMG_URL + item.thumbnail}
                              className="product"
                              alt="Check"
                              onClick={async () => {
                                await onChangeVariant(item, "Single", "1");
                                await setImages(item?.s_p_v_a_d_images);
                              }}
                            />
                          </div>
                        ))}
                      </div> */}
                    </>
                  )}

                  {MultipleVariantTwo && (
                    <>
                      <p className="product-id">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="standard-section">
                        {getValues("variant_details")?.map((item, index) => (
                          <Button
                            variant="standard"
                            className={getValues("activeVariant") === item?.id ? "active" : ''}
                            key={index}
                            onClick={async () => {
                              await setValue('activeVariant', item?.id);
                              await setValue('activeSubVariant', item?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.id);
                              await setValue(
                                "variant_one",
                                item?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name ?? ""
                              );

                              await setValue(
                                "variant_one_details",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds ?? []
                              );

                              await setImages(
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                              );

                            }}
                          >
                            {item?.s_sub_attribute?.name ?? "Unknown"}
                          </Button>
                        ))}
                      </div>

                      <p className="quantity">2. {getValues("variant_one")}</p>

                      <div className="standard-section">
                        {getValues("variant_one_details")?.map(
                          (item, index) => (
                            <Button
                              variant="standard"
                              className={getValues("activeSubVariant") === item?.id ? "active" : ''}
                              key={item?.id ?? index}
                              onClick={async () => {

                                await setValue('activeSubVariant', item?.id);
                                onChangeVariant(item, "Multiple", "2");
                                setImages(
                                  item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          )
                        )}
                      </div>
                    </>
                  )}

                  {MultipleVariantThree && (
                    <>
                      <p className="product-id">Variations</p>
                      <p className="quantity">1. {getValues("variant")}</p>

                      <div className="standard-section">
                        {getValues("variant_details")?.map((item, index) => (
                          <Button
                            variant="standard"
                            className={getValues("activeVariant") === item?.id ? "active" : ''}
                            key={item?.id ?? index}
                            onClick={async () => {

                              await setValue('activeVariant', item?.id);

                              await setValue('activeSubVariant', item?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.id);

                              await setValue('activeSubChildVariant', item?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_as?.[0]
                                ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id);

                              await setValue(
                                "variant_one",
                                item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                  ?.name ?? ""
                              );

                              await setValue(
                                "variant_one_details",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds ?? []
                              );

                              setValue(
                                "variant_two",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                              );

                              setValue(
                                "variant_two_details",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds
                              );

                              setValue(
                                "sku_id",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                              );
                              setValue(
                                "mrp",
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                  ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                              );

                              setImages(
                                item?.s_p_v_a_one_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                              );
                            }}
                          >
                            {item?.s_sub_attribute?.name ?? "Unknown"}
                          </Button>
                        ))}
                      </div>

                      <p className="quantity">2. {getValues("variant_one")}</p>

                      <div className="standard-section">
                        {getValues("variant_one_details")?.map(
                          (item, index) => (
                            <Button
                              variant="standard"
                              className={getValues("activeSubVariant") === item?.id ? "active" : ''}
                              key={item?.id ?? index}
                              onClick={async () => {
                                await setValue('activeSubVariant', item?.id);
                                await setValue('activeSubChildVariant', item?.s_p_v_a_one_d_a_d_as?.[0]
                                  ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id);
                                setValue(
                                  "variant_two",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute
                                    ?.name
                                );

                                setValue(
                                  "variant_two_details",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds
                                );

                                setValue(
                                  "sku_id",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                                );
                                setValue(
                                  "mrp",
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                );

                                setImages(
                                  item?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          )
                        )}
                      </div>

                      <p className="quantity">3. {getValues("variant_two")}</p>

                      <div className="standard-section">
                        {getValues("variant_two_details")?.map(
                          (item, index) => (
                            <Button
                              variant="standard"
                              className={getValues("activeSubChildVariant") === item?.id ? "active" : ''}
                              key={item?.id ?? index}
                              onClick={async () => {
                                await setValue('activeSubChildVariant', item?.id);
                                onChangeVariant(item, "Multiple", "3");
                                setImages(
                                  item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {/* <div className="colourSection">
                    {colors.map((color, index) => (
                      <div
                        key={index}
                        className={`colorBox ${color}-box`}
                      ></div>
                    ))}
                  </div>

                  <div className="sizeSection mt-3">
                    <div className="sizeNumber button_slide slide_down active">
                      38
                    </div>
                    <div className="sizeNumber button_slide slide_down">39</div>
                    <div className="sizeNumber button_slide slide_down">40</div>
                    <div className="sizeNumber button_slide slide_down">41</div>
                    <div className="sizeNumber button_slide slide_down">42</div>
                    <div className="sizeNumber button_slide slide_down">43</div>
                    <div className="sizeNumber button_slide slide_down">44</div>
                  </div> */}

                  <div className="standard-section">
                    <div className="buttons-section">
                      {checkExits ? (
                        <Button
                          variant="primary"
                          className="gotoCartbtn"
                          onClick={handleRemoveFromCart}
                        >
                          <FontAwesomeIcon icon={faCartShopping} /> Remove Cart
                        </Button>
                      ) : signin ? (
                        <Button
                          variant="primary"
                          className="gotoCartbtn"
                          onClick={handleAddToCart}
                        >
                          <FontAwesomeIcon icon={faCartShopping} /> Add Cart
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="gotoCartbtn"
                          onClick={() => navigate("/login")}
                        >
                          <FontAwesomeIcon icon={faCartShopping} /> Add Cart
                        </Button>
                      )}

                      <Button className="gotoCartbtn" onClick={BuyNowClick}>
                        <FontAwesomeIcon icon={faLock} /> Buy Now
                      </Button>
                    </div>
                  </div>

                  <div>
                    <div className="accordionHead">
                      <h3>PRODUCT DESCRIPTION</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.s_product_information?.p_description,
                        }}
                      ></p>
                      {/* <p>
                        Update your look with this Multi Print Slim Fit shirt
                        from Peter England and enjoy the attention.
                      </p> */}
                    </div>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          PRODUCT DETAILS<span></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="accordionContent"
                            dangerouslySetInnerHTML={{
                              __html: data?.caution_and_safety,
                            }}
                          ></div>
                          {/* <div className="accordionContent">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-6">
                                <div className="contentHead">
                                  <h3>StyleCode:</h3>
                                  <p>ASSFCUSPFF85741</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Sleeves:</h3>
                                  <p>Full Sleeves</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Color:</h3>
                                  <p>Multi</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Brand:</h3>
                                  <p>Peter England</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Product Type:</h3>
                                  <p>Shirt</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Fit:</h3>
                                  <p>Shirt</p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-6">
                                <div className="contentHead">
                                  <h3>Pattern:</h3>
                                  <p>Slim</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Occasion:</h3>
                                  <p>Formal</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Cuffs:</h3>
                                  <p>Regular Cuffs</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Sub Brand:</h3>
                                  <p>Peter England - Multi Slim Fit</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Material:</h3>
                                  <p>Cotton</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Color:</h3>
                                  <p>Regular Collar</p>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          PRODUCT DECLARATION <span></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                data?.s_product_information?.p_hightlights,
                            }}
                          ></p>
                          {/* <h4 className="product">
                            Product Highlight: Luxurious Comfort and Timeless
                            Elegance with Our Sofa
                          </h4>
                          <p className="product-text">
                            Indulge in the epitome of comfort and style with our
                            exquisite sofa, meticulously crafted to elevate your
                            living space to new heights of sophistication. Here
                            are the key highlights of our sofa:
                          </p> */}
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          DELIVERY & RETURNS <span></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h4 className="product">
                            Product Highlight: Luxurious Comfort and Timeless
                            Elegance with Our Sofa
                          </h4>
                          <p className="product-text">
                            Indulge in the epitome of comfort and style with our
                            exquisite sofa, meticulously crafted to elevate your
                            living space to new heights of sophistication. Here
                            are the key highlights of our sofa:
                          </p>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    </Accordion>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ProductCart;
