import React from 'react'
import Account from './Account/Account'

function MyAccount() {
  
  return (
    <>
    <Account/>
    </>
  )
}

export default MyAccount