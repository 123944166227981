import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../utils/context";
import './OurStory.css'
const OurStory = () => {

    const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const [data, setData] = useState();
    const getBanner = async () => {
        const res = await getData(`/outer-website/about-us/our-story?domain_id=${sellerDomain}`);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getBanner();

    }, [sellerDomain]);
    return (
        <>
            <section className='our-story'>
                <div className='container'>
                    <div className='row'>
                        <div className="text-center">
                            <h1 className="hot-title">Our Story</h1>
                            <div className="title-border"></div>
                            <p className="sub-text my-4">{htmlToReactParser.parse(data?.description) || ""}</p>
                            <div className="col-md-10 mx-auto">
                                <h5 className="h5-quote"> {htmlToReactParser.parse(data?.description_two) || ""} </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory