// import React, { useState, useEffect } from 'react';
// import "./Pagenation.css"
// import Pagination from 'unify-react-desktop/build/Pagination';

// function Pagenation() {
//   const [pagination, setPagination] = useState([1]);
//   const [currentBatch, setCurrentBatch] = useState(1);
//   const [isHashNewBatch, setIsHashNewBatch] = useState(false);
//   const [indexActive, setIndexActive] = useState(0);

//   const currentData = 50;
//   const perPage = 10;

//   useEffect(() => {
//     const newPagination = [];
//     const totalPagination = Math.ceil(currentData / perPage);

//     for (let i = 1; i <= totalPagination; i++) {
//       newPagination.push(i);
//     }

//     if (totalPagination === 5 && currentData % 10 === 0) {
//       setIsHashNewBatch(true);
//     }

//     setPagination(newPagination);
//   }, []);

//   useEffect(() => {
//     if (indexActive > 0 && indexActive % 5 === 0 && isHashNewBatch) {
//       let newPagination = pagination;

//       // Simulating fetching data from API
//       const newData = 79;
//       const totalPagination = Math.ceil(newData / perPage);

//       for (let i = indexActive; i <= totalPagination + indexActive; i++) {
//         newPagination.push(i);
//       }

//       if (totalPagination % 5 === 0 && newData % 10 === 0) {
//         setIsHashNewBatch(true);
//       } else {
//         setIsHashNewBatch(false);
//       }

//       console.log({ isHashNewBatch });

//       setPagination(newPagination);
//     }
//   }, [indexActive, isHashNewBatch, pagination]);

//   const handleItemClick = (index) => {
//     setIndexActive(index);
//   };

//   return (
//     <div className="Pagenation">
//       <Pagination items={pagination} indexActive={indexActive} onItemClick={handleItemClick} />
//     </div>
//   );
// }

// export default Pagenation;


import React from 'react'
import "./Pagenation.css"
import { Pagination } from 'react-bootstrap'

function Pagenation() {
  return (
    <div className="Pagenation">
    <Pagination>
    <Pagination.First />
    <Pagination.Item active>{1}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item >{4}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Last />
  </Pagination>
  </div>
  )
}

export default Pagenation