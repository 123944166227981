import React, { useContext, useEffect, useState } from "react";
import "./Our_Products_Page.css";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Product_Swiper from "../Common_Component/Product_Swiper/Product_Swiper";
import Banner_Card from "../Common_Component/Banner_Card/Banner_Card";
import Heart_Component from "../Common_Component/Heart_Component/Heart_Component";
import Color_Cart_Holder from "../Common_Component/Color_Cart_Holder/Color_Cart_Holder";
import Product_Details_Div from "../Common_Component/Product_Details_Div/Product_Details_Div";
import { Form, Nav, Tab } from "react-bootstrap";
import Buttons from "../Common_Component/Buttons/Buttons";
import { Link, useNavigate, useParams } from "react-router-dom";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent";
import Pagenation from "../Common_Component/Pagenation/Pagenation";
import {
  AllProducts,
  Category,
  Products,
  getProductMax,
  PopularProductdetails,
  AllSubCategory,
  AllChildCategory,
} from "../../utils/apis/Product/Product";
import { Context } from "../../utils/context";


import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";


import Veggies_Section_Card from "../Common_Component/Veggies_Section_Card/Veggies_Section_Card"
import Category_Card from "../Common_Component/Category_Card/Category_Card";
import Veiw_All_Card from "../Common_Component/Veiw_All_Card/Veiw_All_Card";
import { GetAllShopByCategory } from "../../utils/apis/master/Master";



const popularProducts = [
  {
    imgSource: "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
    detailsLink: "/productdescription",
    subheading: "Peter England - ",
    spanText: "Formal Shirt ",
    price: "₹ 1,234.00 ",
    subPrice: "₹ 1,234.00 ",
  },
  {
    imgSource: "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
    detailsLink: "/productdescription",
    subheading: "Peter England - ",
    spanText: "Formal Shirt ",
    price: "₹ 1,234.00 ",
    subPrice: "₹ 1,234.00 ",
  },
  {
    imgSource: "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
    detailsLink: "/productdescription",
    subheading: "Peter England - ",
    spanText: "Formal Shirt ",
    price: "₹ 1,234.00 ",
    subPrice: "₹ 1,234.00 ",
  },
];

const renderCategoryTabs = () => {
  const categories = [
    {
      labelText: "Men",
      id: "1",
    },

    {
      labelText: "Women",
      id: "2",
    },

    {
      labelText: "Kids",
      id: "3",
    },
  ];

  return categories.map((category, index) => (
    <Nav.Item key={index}>
      <div className=" option_text" eventKey={index + 1}>
        <Form>
          <Form.Check
            type="checkbox"
            id={category.id}
            label={category.labelText}
          />
        </Form>
      </div>
    </Nav.Item>
  ));
};

const renderSizeTabs = () => {
  const categoriesSize = [
    {
      labelText: "Small",
      id: "1",
    },

    {
      labelText: "Medium",
      id: "2",
    },

    {
      labelText: "Large",
      id: "3",
    },

    {
      labelText: "Extra Large",
      id: "4",
    },
  ];

  return categoriesSize.map((categorySizeOption, indexSize) => (
    <div key={indexSize}>
      <div className=" nav-link option_text" eventKey={indexSize + 1}>
        <Form>
          <Form.Check
            type="checkbox"
            id={categorySizeOption.id}
            label={categorySizeOption.labelText}
          />
        </Form>
      </div>
    </div>
  ));
};

const renderPopularProducts = () => {
  return popularProducts.map((product, index) => (
    <div key={index} className="Popular_Product_holder">
      <div className="row">
        <div className="col-xl-4 col-md-12 col-sm-12 ">
          <Link to={product.detailsLink}>
            <div className="img-holder">
              <img
                className="Popular_Product_img"
                src={process.env.PUBLIC_URL + product.imgSource}
                alt={`Popular_Product_img-${index}`}
              />
            </div>
          </Link>
        </div>
        <div className=" col-xl-8 col-md-12  col-sm-12">
          <div className="product_info_holder">
            <h1 className="subheading">
              {product.subheading}{" "}
              <span className="spanText">{product.spanText}</span>
            </h1>
            <div>
              <span className="spanText ">{product.price}</span>
              <span className="label_text subPrice">{product.subPrice}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

const renderPopularProductsTabs = () => {
  return <div className="Popular_Product">{renderPopularProducts()}</div>;
};

const breadcrumbItems = [
  { text: "Home", link: "/" },
  { text: "All Products", className: "active" },
];




function Our_Products_Page() {

  const { sellerId, IMG_URL, wishlistContext, sellerDomain } = useContext(Context);
  const [categorydata, setCategory] = useState([]);

  const navigate = useNavigate();
  const getAllData = async () => {
    const res = await Category(sellerDomain);
    setCategory(res.data);
    const category = localStorage.getItem("web2_category_id");

    if (category !== null) {
      await getSubCategory(category, category);
    } else {
      await getAllProducts(res?.data?.[0]?.category_id);
      await getSubCategory(res?.data?.[0]?.category_id, res?.data?.[0]?.category_id);
    }
  };
  const [activeCategory, setActiveCategory] = useState();

  console.log(activeCategory, 'active');

  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const getSubCategory = async (category_id, seller_cat_id) => {
    await setActiveCategory(category_id);
    const res = await AllSubCategory(sellerDomain, category_id);
    const subcategory = localStorage.getItem("web2_sub_category_id");

    if (res?.success) {
      setSubCategory(res?.data);

      if (subcategory !== null) {
        getChildCategory(subcategory, subcategory);
      } else {
        if (res?.data?.[0]?.sub_category_id) {
          getChildCategory(
            res?.data?.[0]?.sub_category_id,
            res?.data?.[0]?.sub_category_id
          );
          await getAllProducts(category_id, res?.data?.[0]?.sub_category_id);
        } else {
          await getAllProducts(category_id);
        }
      }
    }
  };

  const [activeChildCategory, setActiveChildCategory] = useState("");
  const [childCategory, setChildCategory] = useState([]);
  const getChildCategory = async (sub_category_id, seller_cat_id) => {
    await setActiveSubCategory(sub_category_id);
    const res = await AllChildCategory(sellerDomain, sub_category_id);
    if (res?.success) {
      setChildCategory(res?.data);
      console.log(res?.data);
      if (res?.data?.[0]?.child_category_id) {
        await getAllProducts(
          res?.data?.[0]?.category_id,
          res?.data?.[0]?.sub_category_id,
          res?.data?.[0]?.child_category_id
        );
      } else {
        await getAllProducts(activeCategory, sub_category_id);
      }
    }
  };

  useEffect(() => {
    getAllData();
    getAllProductsMax();
    getAllPopularProducts();
  }, [sellerDomain]);

  const [products, setProducts] = useState([]);
  const [productsMax, setProductsMax] = useState([]);
  const [popularProduct, setPopularProduct] = useState([]);

  const getAllProducts = async (
    category_id = "",
    sub_category_id = "",
    child_category_id = ""
  ) => {
    // const storedName = localStorage.getItem("outer5_prodName");

    const res = await Products(
      category_id,
      sub_category_id,
      child_category_id,
      sellerDomain,
      sellerId,
    );
    if (res?.success) {
      setProducts(res?.data);
      wishlistContext();
    }
  };

  const getAllProductsMax = async () => {
    const res = await getProductMax();
    setProductsMax(res);
    wishlistContext();
  };
  const getAllPopularProducts = async () => {
    const res = await PopularProductdetails(sellerId);
    if (res?.success) {
      setPopularProduct(res.data);
    }
  };

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleMainSliderClick2 = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <div className="Our_Products_Page Category ">
        <div className="container">
          <div className="mb-4">
            <BreadcrumComponent items={breadcrumbItems} />
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">

            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-md-3 col-md-3 col-sm-3 col-5 ">
                <Nav variant="pills" className="categoryTabs">
                  <div className="filter-holder">
                    <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Categories</h1>
                        <div className="title-border"></div>
                      </div>
                      {/* {renderCategoryTabs()}
                       */}
                      {categorydata?.map((category, index) => (
                        <Nav.Item key={index}>
                          <div className={activeCategory == category?.category_id ? 'option_text active' : 'option_text'} onClick={() => getSubCategory(category?.category_id, category?.category_id)}>
                            {category?.name}

                          </div>
                        </Nav.Item>
                      ))}
                    </div>

                    {/* <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Filter by Price</h1>
                        <div className="title-border"></div>
                      </div>

                      <div className="rangebar_holder">
                        <RangeSlider />
                      </div>

                      <div className="filter_price_text">
                        <div>
                          <span className="spanText me-1">Price:</span>
                          <span className="label_text subPrice">
                            $0 - ${productsMax.data}
                          </span>
                        </div>
                      </div>
                    </div> */}

                    <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Popular Product</h1>
                        <div className="title-border"></div>
                      </div>
                      {popularProduct?.map((product, index) => (
                        <div key={index} className="Popular_Product_holder">
                          <div className="row">
                            <div className="col-xl-4 col-md-12 col-sm-12 ">
                              <Link to={product.detailsLink}>
                                <div className="img-holder">
                                  <img
                                    className="Popular_Product_img"
                                    src={IMG_URL + product.thumbnail}
                                    alt={`Popular_Product_img-${index}`}
                                    style={{ width: "100px", height: "auto" }}
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className=" col-xl-8 col-md-12  col-sm-12">
                              <div className="product_info_holder">
                                <h1 className="subheading">
                                  {product.product_title}{" "}
                                  <span className="spanText">
                                    {product.product_generic_name}
                                  </span>
                                </h1>
                                <div>
                                  <span className="spanText ">
                                    ₹ {product.selling_price}
                                  </span>
                                  <span className="label_text subPrice">
                                    ₹ {product.mrp}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Sizes</h1>
                        <div className="title-border"></div>
                      </div>

                      {renderSizeTabs()}
                    </div> */}
                  </div>
                </Nav>
              </div>

              <div className="col-xxl-9 col-xl-9 col-md-9 col-lg-9 col-sm-9 col-7">
                <div className="subcategorytabss">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="vegfirst">
                    <Row className="vegetable-sec">
                      <Col
                        lg={3}
                        md={4}
                        sm={6}
                        className=" mb-4"
                      >
                        <div className="bg-color">
                          <div className="product-name">
                            {subCategory.length > 0 && (
                              <p className="subcattt">Subcategory</p>
                            )}
                          </div>
                          <Nav variant="pills" className="flex-column">
                            {subCategory?.map((value, index) => (
                              <Nav.Item>
                                <Nav.Link
                                  className={
                                    activeSubCategory == value?.sub_category_id
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    getChildCategory(
                                      value?.sub_category_id,
                                      value?.sub_category_id
                                    )
                                  }
                                >
                                  {value?.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        </div>
                      </Col>

                      <Col lg={9} md={8} className="px-lg-0 px-md-0 px-sm-none px-none">
                        <Tab.Content className="subcat-tabcobt">
                          <Tab.Pane eventKey="vegfirst">
                            <Row>

                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="px-lg-0 px-md-0 px-sm-none px-none"
                              >
                                <Swiper
                                  breakpoints={{
                                    0: {
                                      slidesPerView: 1,
                                      spaceBetween: 0,
                                    },
                                    380: {
                                      slidesPerView: 2,
                                    },
                                    575: {
                                      slidesPerView: 3,
                                    },
                                    768: {
                                      slidesPerView: 3,
                                    },
                                    992: {
                                      slidesPerView: 4,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                    1400: {
                                      slidesPerView: 4,
                                      spaceBetween: 0,
                                    },
                                  }}
                                  navigation={false}
                                  className="mySwiper"
                                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                                >
                                  {childCategory?.map((value, index) => (
                                    <SwiperSlide>
                                      <Veggies_Section_Card child={value} product={getAllProducts} />
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                {childCategory.length > 4 && (
                                  <div class="silder-btn">
                                    <div
                                      className="back-btn"
                                      onClick={() => handleMainSliderClick("prev")}
                                    > <img src={process.env.PUBLIC_URL + '/assets/Icon/left.png'} /> </div>
                                    <div
                                      className="next-btn"
                                      onClick={() => handleMainSliderClick("next")}
                                    ><img src={process.env.PUBLIC_URL + '/assets/Icon/right.png'} /> </div>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane eventKey="vegsecond">
                            <Row>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="px-lg-0 px-md-0 px-sm-none px-none"
                              >
                                <Swiper
                                  breakpoints={{
                                    0: {
                                      slidesPerView: 1,
                                      spaceBetween: 0,
                                    },
                                    380: {
                                      slidesPerView: 2,
                                    },
                                    575: {
                                      slidesPerView: 3,
                                    },
                                    768: {
                                      slidesPerView: 3,
                                    },
                                    992: {
                                      slidesPerView: 4,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                    1400: {
                                      slidesPerView: 4,
                                      spaceBetween: 0,
                                    },
                                  }}
                                  navigation={false}
                                  className="mySwiper"
                                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                                >

                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <Veggies_Section_Card />
                                  </SwiperSlide>

                                </Swiper>
                                <div class="silder-btn">
                                  <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick2("prev")}
                                  > <img src={process.env.PUBLIC_URL + '/assets/Icon/left.png'} /> </div>
                                  <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick2("next")}
                                  ><img src={process.env.PUBLIC_URL + '/assets/Icon/right.png'} /> </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>

                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>

                <div className="tab-content mt-5">
                  <div className="Our_Products_Page_Card">
                    <div className="row">
                      {products?.data?.map((product, index) => (
                        <div
                          key={index}
                          className="col-xxl-4 col-xl-4 col-md-4 col-sm-6"
                          product
                        >
                          <Banner_Card
                            product={product}
                            imgClassName="Tab_Slider_Img"
                            // detailsLink={product.detailsLink}
                            imgSource={IMG_URL + product.thumbnail}
                            heartClassName={"Heart_Like"}
                            HeartLikeComponent={
                              <Heart_Component id={product.id} />
                            }
                            ProductDetailsClassName={"Product_Details"}

                            ProductDetailsSection={
                              <Product_Details_Div
                                product={product}
                                // ColorCartHolder={<Color_Cart_Holder />}
                                id={product.id}
                                subheading={product.product_title}
                                spanText={product.product_generic_name}
                                // productDescription={product.productDescription}
                                Price={product.selling_price}
                                subPrice={product.mrp}
                              />
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <Pagenation products={products}/> */}
              </div>

            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default Our_Products_Page;
