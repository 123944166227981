import React, { useContext, useState } from "react";
import "./Order_Cancle.css";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Successfull_Modal from "../../../Common_Component/Successfull_Modal/Successfull_Modal";
import Cancle_Reason_Modal from "../../../Common_Component/Cancle_Reason_Modal/Cancle_Reason_Modal";
import { Context } from "../../../../utils/context";

import { OrderCancel } from "../../../../utils/apis/orderProcess/orderProcess";

// import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
function Order_Cancle(props) {


  const [modalShow, setModalShow] = useState(false);
  const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);
  const navigate = useNavigate();
  


  const { IMG_URL } = useContext(Context);

  const [reason, setReason] = useState('');
  const [remark, setRemark] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async () => {
    if(reason){
      const data = {
        order_id: props.data?.id,
      }
      
        data.reason = reason;
      
      if(remark){
        data.remark = remark;
      }
      const res = await OrderCancel(data);
      if(res?.success){
        setModalShow(true);
        props.getOrders();
        setTimeout(() => {
          setModalShow(false);
          props.onHide();
          navigate("/my-account");
        }, 3000);
      }
    }else{
      setError('Select Reason for cancellation');
    }
   
  }

  

  return (
    <>
      <div className="Order_Cancle my-account">
        <div className="container">
          <div className="product-section-div">
            <div className="product-div">
              <div className="row">
                {props.data?.o_w_orders_details?.map((detail, detailIndex) => (
                  <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                    <div className="product">
                      <img
                        className="img-set"
                        src={
                          IMG_URL +
                          detail?.s_product_variant_attribute_detail?.thumbnail
                        }
                        alt=""
                      />
                    </div>
                  </div>
                ))}
                {/* <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                  <div className="product">
                    <img
                      className="img-set"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/My_Profile/tomato.png"
                      }
                      alt=""
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="text-holder">
              <p>Order ID {props.data?.invoice_number}</p>
              <h4>Purchased Items {props.data?.o_w_orders_details.length} </h4>
              {/* <span>(Approx. 600 -1000 gm)</span> */}
              <h4>₹ {props.data?.amount}</h4>
            </div>
          </div>

          <div
            className="Cancellation"
            onClick={() => setModalCanleReasonShow(true)}
          >
            <p>
              Select a reason for cancellation{" "}
              <FontAwesomeIcon
                className="ms-2"
                icon="fa-solid fa-angle-right"
              />
            </p>
          </div>

          <div className="Cancellation  Cancellation-reason">
            <p>
              Could you tell us a reason for cancellation?{" "}
              <span>(optional)</span>
            </p>

            <Form>
              <Form.Control
                as="textarea"
                role="1"
                placeholder="Type a reason"
                onChange={(e) => setRemark(e.target.value)}
              />
            </Form>

            <div className="btn-holder">
              <Link>
                <Button className="cancel-btn" onClick={props.onHide}>Cancel</Button>
              </Link>

              <Button
                onClick={handleSubmit}
                className="submit-btn"
              >
                Submit
              </Button>
            </div>
            <div className="btn-holder">
              <sub className="text text-danger">{error}</sub>
            </div>
          </div>
        </div>
      </div>

      <Successfull_Modal
        message=" Your order has been canceled successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Cancle_Reason_Modal
        reason={reason}
        setReason={setReason}
        show={modalCanleReasonShow}
        setError={setError}
        onHide={() => setModalCanleReasonShow(false)}
      />
    </>
  );
}

export default Order_Cancle;
