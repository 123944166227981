import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../utils/context";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import Buttons from "../Common_Component/Buttons/Buttons";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import context from "react-bootstrap/esm/AccordionContext";
import { RegxExpression } from "../../utils/common";

import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal"

const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const { getData, postData, IMG_URL, Select2Data, sellerId, sellerDomain } =
    useContext(Context);

  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };

  const handleArrowClick = () => {
    setShowContactTextHolder(true);
    setShowForm(false);
    setShowBg(false);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (d) => {
    //alert('ss');
    try {
      d.seller_id = sellerId;
      d.domain_id = sellerDomain;
      const res = await postData(`/outer-website/contact-us`, d);
      if (res?.success) {
        console.log(res, "submit");
        reset();
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="contact-us">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        {/* <div className="contact_form_holder "> */}
        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-md-4">
                <div
                  className={`contact-text-holder ${showContactTextHolder ? "" : "d-none"
                    }`}
                >
                  <div className="heading-holder mb-4">
                    <h2>Contact us</h2>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-phone"
                      />
                    </div>
                    <div className="address-holder">
                      <p>01123968786</p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-envelope"
                      />
                    </div>
                    <div className="address-holder">
                      <p>support@ninjakraft.com</p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                    <div className="address-holder">
                      <p>
                        {" "}
                        516/4, Gali Rajan Kalan, Kashmere <br /> Gate Delhi,
                        Delhi, 110006{" "}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-map-location-dot"
                      />
                    </div>
                    <div
                      className="address-holder map-text"
                      onClick={handleMapTextClick}
                    >
                      <p>Find Us on Map</p>
                    </div>
                  </div>

                  <div className="border-holder  my-4"></div>
                  <div className="social-icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/contact/facebook.png"
                      }
                      className="facebook-icon me-2"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/contact/linkedin.png"
                      }
                      className="facebook-icon me-2"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/contact/pinterest.png"
                      }
                      className="facebook-icon me-2"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/contact/twitter.png"
                      }
                      className="facebook-icon"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${showForm
                  ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                  : "col-lg-8 col-md-8 scale-up-hor-right"
                  }`}
              >
                <div className="form-holder ">
                  <div
                    className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                      }`}
                    onClick={handleArrowClick}
                  >
                    {/* <FontAwesomeIcon className="left-arrow" icon="fa-solid fa-arrow-left" /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/Icon/arrow-left.png"
                      }
                      className="left-arrow"
                    />
                  </div>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form_container">
                      <Form.Group
                        className="mb-1"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          {...register("name", {
                            required: "Name is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.name && (
                          <sub className="text text-danger">
                            {errors.name.message}
                          </sub>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="mb-1"
                        controlId="exampleForm.ControlInput1 "
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Email ID"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: RegxExpression.email,
                              message: "Invalid email address",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                        />
                        {errors?.email && (
                          <sub className="text text-danger">
                            {errors.email.message}
                          </sub>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="mb-1"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          {...register("subject", {
                            required: "Subject is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.subject,
                          })}
                        // onKeyDown={(event) => {
                        //   if (!/[A-Z-a-z ]/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        />
                        {errors?.subject && (
                          <sub className="text text-danger">
                            {errors.subject.message}
                          </sub>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="mb-1"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          type="text"
                          name="message"
                          as="textarea"
                          placeholder="Message"
                          {...register("message", {
                            required: "message is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                        // onKeyDown={(event) => {
                        //   if (!/[A-Z-a-z ]/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        />
                        {errors?.message && (
                          <sub className="text text-danger">
                            {errors.message.message}
                          </sub>
                        )}
                      </Form.Group>

                      <div className="btn-holder mt-4">
                        {/* <Buttons
                          className="SubmitBtn"
                          text="Send Message"
                          padding="7px 20px"
                          type="submit"

                        /> */}
                        <button
                          className="SubmitBtn"
                          padding="7px 20px"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Successfull_Modal show={modalShow} message={"Form Submitted Successfully"} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ContactUs;
