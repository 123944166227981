import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const sendMobileOTP = async (contact_no) => {
  try {
    const res = await postData(`/seller/verify-otp/mobile-otp-send`, {
      contact_no,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const resendMobileOTP = async (contact_no) => {
  try {
    const res = await postData(`/without-login/verify-otp/mobile-otp-resend`, {
      contact_no,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailOTP = async (email) => {
  try {
    const res = await postData(`/outer-website/verify-otp/email-otp-outer-send`, { email });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyMobileOTP = async (contact_no, otp) => {
  try {
    const res = await postData(`/seller/verify-otp/mobile-verify-otp`, {
      contact_no,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyEmailOTP = async (email, otp) => {
  try {
    const res = await postData(`/seller/verify-otp/email-verify-otp`, {
      email,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassWord = async (data) => {
  try {
    const res = await postData(`/outer-website/auth/reset-password`,data);

    return res;
  } catch (error) {
    console.log(error);
  }
};


