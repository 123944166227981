import React, { useContext } from "react";
import "./Purchased_Items_Modal.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button, Modal } from "react-bootstrap";
// import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
function Purchased_Items_Modal(props) {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }


  const { IMG_URL } = useContext(Context);
  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Purchased_Items_Modal my-account"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="product-section-div">
              <div className="row">
                <div className="col-xxl-5 col-lg-5 col-md-7 col-sm-6 col-12">
                  <div className="border-text-right">
                    <p className="date">Ordered Date : {new Date(props.data?.createdAt).toLocaleDateString('en-US', {
                      day: 'numeric', month: 'short', year: 'numeric'
                    })}</p>
                    <h6 className="items">Purchased Items {props.data?.o_w_orders_details.length}</h6>
                    <h6 className="price">₹ {props.data?.amount}</h6>
                  </div>
                </div>
                <div className="col-xxl-7 col-lg-7 col-md-5 col-sm-6 col-12">
                  <div>
                    <p className="date">Order ID : {props.data?.invoice_number}</p>
                    <p className="date">Payment Type : {props.data?.payment_mode}</p>
                    <p className="date">Payment Status : {props.data?.o_w_payment_status?.status === 1 ? 'Paid' : 'UnPaid'}</p>
                  </div>
                </div>
              </div>

              <div className="product-div">
                <div className="row">
                  {props.data?.o_w_orders_details?.map((detail, detailIndex) => (
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3  col-3   ">
                      <div className="product">
                        <img
                          className="img-set"
                          src={
                            IMG_URL +
                            detail?.s_product_variant_attribute_detail?.thumbnail
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                  {/* <div className=" col-xl-2 col-lg-2 col-md-3 col-sm-3  col-3   ">
                    <div className="product">
                      <img
                        className="img-set"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/My_Profile/tomato.png"
                        }
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              {props.data?.o_w_order_status?.id === 5 || props.data?.o_w_order_status?.id === 6 ? (
                <div className="stepper-div">
                  <h5 className="text-danger mt-4">{props.data?.o_w_order_status?.id === 5 ? "Order Has Been Cancelled" : "Order Returned"}</h5>
                </div>
              ) : (
                <>
                  <div className="pb-md-0 pb-sm-3 pb-4">
                    <h6 className="order">Order Status</h6>
                  </div>
                  <div className="stepper-div">
                    <ProgressBar percent={props.data?.o_w_order_status?.id === 1 ? 0 : props.data?.o_w_order_status?.id === 2 ? 34 : props.data?.o_w_order_status?.id === 3 ? 68 : props.data?.o_w_order_status?.id === 4 ? 100 : 0}>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${accomplished ? "accomplished" : null
                              }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Order Placed</h6>
                              {/* <p className="date">20 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${accomplished ? "accomplished" : null
                              }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Processing</h6>
                              {/* <p className="date">20 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${accomplished ? "accomplished" : null
                              }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Shipped</h6>
                              {/* <p className="date">Est. 30 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>

                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${accomplished ? "accomplished" : null
                              }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Delivered</h6>
                              {/* <p className="date">Est. 30 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>
                    </ProgressBar>
                  </div>
                </>
              )}
              <Link to={`/order-return/${props.data?.id}`}>
                <div className="return-button ">
                  <Button
                    className="return-btn">Return</Button>
                </div>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Purchased_Items_Modal;
