import React from 'react'
import Information from './Information/Information'

function AccountInfo() {
  return (
    <>
    <Information/>
    </>
  )
}

export default AccountInfo