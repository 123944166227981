import React, { useContext, useEffect, useState } from "react";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Product_Swiper from "../../Common_Component/Product_Swiper/Product_Swiper";
import "./SimilarPatterns.css";
import { Products } from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";
const SimilarPatterns = ({ heading, category }) => {


  const { sellerId, sellerDomain } = useContext(Context);

  const [products, setProduct] = useState([]);
  const getProducts = async () => {
    const res = await Products(category, "", "", sellerDomain, sellerId);
    if (res?.success) {
      setProduct(res?.data?.data);
    }
  };

  console.log(products);

  useEffect(() => {
    getProducts();
  }, [sellerId, category]);
  return (
    <>
      <section className="SimilarPattern">
        <div className="container">
          <Heading_Holder headingText={heading} className="heading-holder" />

          <Product_Swiper
            sliderImages={products}
            slidesPerView={4}
            breakpoints={{
              0: { slidesPerView: 2 },
              380: { slidesPerView: 2 },
              485: { slidesPerView: 2 },
              575: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              992: { slidesPerView: 4 },
              1024: { slidesPerView: 4 },
              1200: { slidesPerView: 4 },
              1440: { slidesPerView: 4 },
              2000: { slidesPerView: 4 },
            }}
            categoryActive={category}
          />
        </div>
      </section>
    </>
  );
};

export default SimilarPatterns;
