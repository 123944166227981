import React, { useState, useEffect, useContext } from "react";
import "./Crazy_Deals.css";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { GetAllCrazyDeals } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

function Crazy_Deals() {
  const { getData, IMG_URL, Select2Data, sellerId, sellerDomain } = useContext(Context);
  const [faq, setFaq] = useState([]);
  const getFaq = async () => {
    const res = await GetAllCrazyDeals(sellerDomain);
    if (res?.success) {
      setFaq(res.data);
    }
  };

  useEffect(() => {
    getFaq();
  }, [sellerDomain]);
  // Array containing image sources
  // const imageSources = [
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_1.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_2.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_3.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_1.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_2.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_3.png",
  // ];


  const navigate = useNavigate();
  return (
    <>
      <div className="Crazy_Deals">
        <div className="container">
          <Heading_Holder headingText={"CRAZY DEALS"} />

          <div className="Crazy_Deals_Swiper_holder">
            <Swiper
              className="mySwiper CrazyDealsSwiper"
              spaceBetween={20}
              slidesPerView={3}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                380: {
                  slidesPerView: 2,
                },
                485: {
                  slidesPerView: 2,
                },
                575: {
                  slidesPerView: 2,
                },

                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
                1440: {
                  slidesPerView: 3,
                },
                2000: {
                  slidesPerView: 3,
                },
              }}
            >
              {faq.map((source, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => {
                    localStorage.setItem("web2_category_id", source?.category_id);
                    localStorage.setItem("web2_sub_category_id", source?.sub_category_id);
                    navigate(`/our_products_page/${source.category_id}`);
                  }}
                >
                  <div className="crazybanmain">
                    <Banner_Card
                      className="Crazy_Deals_Swiper_Img_holder"
                      imgClassName="Crazy_Deals_Swiper_Img"
                      overlaySubText={source.s_sub_category?.subcategory_of_seller?.name}
                      overlayText={source.s_sub_category?.subcategory_of_seller?.name}
                      imgSource={IMG_URL + source.s_sub_category?.subcategory_of_seller?.image}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Crazy_Deals;
