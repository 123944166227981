import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getFaqs = async () => {
  try {
    return await getData(`/without-login/all-faq-categories`);
  } catch (error) {
    console.log(error);
  }
};
export const Banners = async () => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.log(error);
  }
};

export const Brands = async (domain_id, type) => {
  try {
    return await getData(`/without-login/masters/all-s-brand?domain_id=${domain_id}&type=${type}`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/without-login/home/get-country`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/without-login/home/get-state/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/home/get-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (name) => {
  try {
    return await getData(`/without-login/masters/allpincode/${name}`);
  } catch (error) {
    console.log(error);
  }
};

export const allPincode = async () => {
  try {
    return await getData(`/without-login/masters/allpincode`);
  } catch (error) {
    console.log(error);
  }
};

export const pincodeWiseAll = async (id) => {
  try {
    return await getData(`/without-login/masters/all-pincode-details/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const SellerGet = async () => {
  try {
    return await getData(`/seller/show`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllWebsiteFaq = async (domain_id) => {
  try {
    return await getData(`/outer-website/faq/faq?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllExclusiveCollection = async (domain_id) => {
  try {
    return await getData(
      `/outer-website/home/all-exclusive-collection?domain_id=${domain_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCrazyDeals = async (domain_id) => {
  try {
    return await getData(
      `/outer-website/home/all-crazy-deals?domain_id=${domain_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopTrends = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-shop-trends?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopByCategory = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-shop-by-category?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopByCategoryNew = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-shop-by-category-new?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllNewArrivals = async (s_supplyer_id) => {
  try {
    return await getData(
      `/outer-website/home/all-new-arrivals?s_supplyer_id=${s_supplyer_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllHomeBanner = async (domain_id) => {
  try {
    return await getData(`/outer-website/home/all-home-banner?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllWishList = async (id) => {
  try {
    return await postData(`/outer-website/wishlist/add`, { product_id: id });
  } catch (error) {
    console.log(error);
  }
};

export const GetWebHeader = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/web-header?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAppSetup = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/app-setup?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};
