import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../utils/context";
import './HappyCustomer.css'
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
const HappyCustomer = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const clientReviews = [
        {
            name: "Kristina",
            review: "Effortless shopping experience with a wide range of products and lightning-fast delivery - this app makes online shopping a breeze!.",
            rating: 5, // Assuming all ratings are 5 stars,
            imgSrc: process.env.PUBLIC_URL + "/assets/about/client-img.png",
        },
        {
            name: "Rose Merry",
            review: "Sleek interface, intuitive navigation, and secure transactions - this e-commerce app sets the standard for user-friendly online shopping.",
            rating: 5, // Assuming all ratings are 5 stars,
            imgSrc: process.env.PUBLIC_URL + "/assets/about/client-img.png",
        },
        {
            name: "John Doe",
            review: "From fashion to electronics, this app has it all! With seamless browsing and hassle-free checkout, it's my go-to for all my online purchases.",
            rating: 5, // Assuming all ratings are 5 stars,
            imgSrc: process.env.PUBLIC_URL + "/assets/about/client-img.png",
        },
        {
            name: "Kristina",
            review: "Effortless shopping experience with a wide range of products and lightning-fast delivery - this app makes online shopping a breeze!.",
            rating: 5, // Assuming all ratings are 5 stars,
            imgSrc: process.env.PUBLIC_URL + "/assets/about/client-img.png",
        },
        {
            name: "Rose Merry",
            review: "Sleek interface, intuitive navigation, and secure transactions - this e-commerce app sets the standard for user-friendly online shopping.",
            rating: 5, // Assuming all ratings are 5 stars,
            imgSrc: process.env.PUBLIC_URL + "/assets/about/client-img.png",
        },
        {
            name: "John Doe",
            review: "From fashion to electronics, this app has it all! With seamless browsing and hassle-free checkout, it's my go-to for all my online purchases.",
            rating: 5, // Assuming all ratings are 5 stars,
            imgSrc: process.env.PUBLIC_URL + "/assets/about/client-img.png",
        },
    ];


    const { getData, IMG_URL, sellerId } = useContext(Context);
    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const [data, setData] = useState();
    const getBanner = async () => {
        const res = await getData(`/outer-website/about-us/happy-customer?seller_id=${sellerId}`);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getBanner();

    }, [sellerId]);


    return (
        <>
            <section className='happy-clients'>
                <div className='container'>
                    <div className='row'>
                        <div class="text-center mb-5">
                            <h3 class="hot-title">Happy Customers</h3>
                            <div class="title-border"></div>
                        </div>

                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            autoplay={{ delay: 300 }}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            className="mySwiper"
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,

                                },
                                420: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                486: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,

                                },
                                992: {
                                    slidesPerView: 3,

                                },
                                1200: {
                                    slidesPerView: 3,

                                },


                            }}
                        >
                            {data?.map((review, index) => (
                                <SwiperSlide key={review?.id}>
                                    <div className="client-div mt-3 mb-5">
                                        <Row>
                                            <Col lg={3} md={4} sm={4} xs={4}>
                                                <img className="client-img" src={IMG_URL + review.image} alt="" />
                                            </Col>
                                            <Col lg={9} md={8} sm={8} xs={8}>
                                                <h5 className="client-name">{review.name}</h5>
                                            </Col>
                                        </Row>
                                        <div className="mt-3">
                                            <p className="client-review">{htmlToReactParser.parse(review?.description) || ""}</p>
                                            <div className="d-flex">
                                                {Array.from({ length: review?.star || 0 }).map((_, i) => (
                                                    <div key={i}><FontAwesomeIcon icon={["fas", "star"]} className='star-icon mt-3' /></div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div class="silder-btn">
                            <div
                                className="back-btn"
                                onClick={() => handleMainSliderClick("prev")}
                            ></div>
                            <div
                                className="next-btn"
                                onClick={() => handleMainSliderClick("next")}
                            ></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HappyCustomer