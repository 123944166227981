import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Success_Modalss from '../../Common_Component/Success_Modal/Success_Modalss';
import Buttons from '../../Common_Component/Buttons/Buttons';
import { Context } from '../../../utils/context';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { getCart } from '../../../utils/apis/addToCart/addToCart';
import { Checkout } from '../../../utils/apis/orderProcess/orderProcess';

const Order_review = ({ handleNext, handlePrevious, paymentMethod, addressSelect }) => {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const [razorid, setRazorId] = useState();
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState(0);
    const { IMG_URL, signin, sellerId, sellerDomain } = useContext(Context);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cart, setCart] = useState([]);

    const handlePhonePePayment = async (amount) => {
        const options = {
            method: 'post',
            url: 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay',
            headers: {
                accept: 'text/plain',
                'Content-Type': 'application/json',
            },
            data: {
                merchantId: 'YOUR_MERCHANT_ID',
                merchantTransactionId: `txn_${Date.now()}`,
                merchantUserId: 'USER_ID',
                amount: amount * 100, // PhonePe amount is in paisa
                redirectUrl: 'YOUR_REDIRECT_URL',
                redirectMode: 'POST',
                callbackUrl: 'YOUR_CALLBACK_URL',
                mobileNumber: 'USER_MOBILE_NUMBER',
                paymentInstrument: {
                    type: 'PAY_PAGE'
                }
            }
        };

        try {
            const response = await axios.request(options);
            if (response.data.success) {
                // Handle success response and proceed with payment
                window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
            } else {
                console.error('Payment initiation failed:', response.data.message);
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

    const finalFunction = async (razor = null) => {
        const data = {
            address_id: addressSelect?.id,
            amount: totalPrice,
            discount: discount,
            payment_mode: razor ? 'online' : 'cod',
            seller_id: sellerId,
            domain_id: sellerDomain,
        };
        let affiliate = "";
        if (Cookies.get('a_id')) {
            affiliate = JSON.parse(Cookies.get('a_id'));
        }

        if (affiliate?.a_id) {
            data.a_id = affiliate?.a_id;
            data.p_id = affiliate?.p_id;
        }
        if (coupon) {
            data.code_id = coupon;
        }
        if (razor) {
            data.payment_id = razor;
        }
        const res = await Checkout(data);
        if (res?.success) {
            placeorderfunc();
        }
    }

    const placeorderfunc = () => {
        setModalShow(true)
        setTimeout(() => {
            setModalShow(false);
            navigate('/');
        }, 3000)
    }

    const cartGet = async () => {
        const res = await getCart(sellerId, sellerDomain);
        if (res?.success) {
            setCart(res?.data);
            var total = 0;
            res?.data?.map((value, index) => {
                total += value?.selling_price * value.quantity;
            });
            setTotalPrice(total);
        }
    };

    useEffect(() => {
        cartGet();
    }, []);

    return (
        <>
            <section className='stepp-inner-main'>
                <div className='orde-status-dv'>
                    <div className='row'>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Order Number</p>
                            <p>0005-12122023</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Status</p>
                            <p>Ordered</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Date</p>
                            <p>{new Date().toLocaleDateString('en-US', {
                                day: 'numeric', month: 'short', year: 'numeric'
                            })}</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Total</p>
                            <p>₹. {totalPrice}</p>
                        </div>
                        <div className='col-md-2 col-6 colwidth'>
                            <p>Payment method</p>
                            <p>{paymentMethod}</p>
                        </div>
                    </div>
                </div>

                <div className='orderr-dettt'>
                    <h6 className='jhber'>Order Details</h6>

                    <div className='row'>
                        {cart?.map((post, index) => (
                            <div key={index} className='col-md-6 detailssss-flex mt-3'>
                                <div className='img-div'>
                                    <img className='userimgg' src={IMG_URL + post.thumbnail} alt={post.product_title} />
                                </div>
                                <div className='contdivvv'>
                                    <p className='name'>{post.product_title}<span>- {post?.product_generic_name}</span></p>
                                    <p className='prodrsvd'>{post?.product_generic_name}</p>
                                    <p className='price'>₹ {post?.selling_price}  <span>₹ {post?.mrp}</span></p>
                                    <p className='sizeeepp'>{post.varint_type}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='totalprieee'>
                        <div className='row mb-4'>
                            <div className='col-lg-10 col-6'>
                                <h6>Shipping & Handling (Flat Rate)</h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6>Flat Rate</h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6>₹. 00.00</h6>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-lg-10 col-6'>
                                <h6>Payment Method</h6>
                            </div>
                            <div className='col-1'>
                                <h6>{paymentMethod}</h6>
                            </div>
                        </div>
                        <div className='row totalbg mb-4'>
                            <div className='col-lg-10 col-6'>
                                <h6>Total</h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6></h6>
                            </div>
                            <div className='col-lg-1 col-6'>
                                <h6>₹. {totalPrice}</h6>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <h6>Address</h6>
                            <hr></hr>
                            <p className='finaladddd'>{addressSelect?.address},{addressSelect?.land_mark},{addressSelect?.city?.name},{addressSelect?.state?.name} - {addressSelect?.pincode?.name}</p>
                        </div>
                    </div>
                </div>

                <div className="save-btn-div mt-5">
                    <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
                    {paymentMethod === 'cod' ? (
                        <Buttons type='button' className="continue-btn" onClick={() => finalFunction()} text={"PLACE ORDER "}> PLACE ORDER </Buttons>
                    ) : (
                        <Buttons type='button' className="continue-btn" onClick={() => handlePhonePePayment(totalPrice)} text={"PLACE ORDER "}> </Buttons>
                    )}
                </div>

                <Success_Modalss texts={"Your order has been placed successfully!"} show={modalShow}
                    onHide={() => setModalShow(false)} />
            </section>
        </>
    );
};

export default Order_review;
