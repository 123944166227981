import React, { useState, useEffect, useContext } from "react";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Login from "../Login";
import SetNewPassword from "../SetNewPassword/SetNewPassword";
import CheckEmail from "../CheckEmail/CheckEmail";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import EmailOtpModal from "../../Common_modal/Email_otp_modal/EmailOtpModal";

import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import {
  sendEmailOTP,
  verifyEmailOTP,
} from "../../../utils/apis/common/Common";

function ResetPassWord() {
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);

  // const handleSubmit = () => {
  //   setShowCheckYourEmail(true);
  //   setTimeout(() => {
  //     setShowCheckYourEmail(false);
  //     setShowSetNewPassword(true);
  //   }, 3000); // Wait for 3 seconds before showing Set_New_Password
  // };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();




  const [otpLoader, setOtpLoader] = useState(false);
  const handleSendOtp = async () => {

    setOtpLoader(true);
    if (getValues("email")) {
      if (RegxExpression.email.test(getValues("email"))) {
        const res = await sendEmailOTP(getValues("email"));
        if (res?.success) {
          setModalShow3(true);
          setSeconds(90);
          clearErrors();
        } else {
          setError("email", {
            message: res?.message?.email,
          });
        }
      } else {
        setError("email", {
          message: "Email Invalid",
        });
      }
    } else {
      setError("email", {
        message: "Email Required",
      });
    }
    setOtpLoader(false);
  };

  const verifyOTP = async (otp) => {
    const formValues = getValues();

    const res = await verifyEmailOTP(getValues("email"), otp);
    if (res?.success) {
      setEmailVerify(true);
      setModalShow3(false);
      clearErrors();
      setSeconds(0);
      setShowSetNewPassword(true);
    } else {
      setOtpErr(res?.message);
    }
  };

  console.log(errors);
  return (
    <>
      <section className="reset-section">
        <Container>
          <div className="LogIn">
            {!showSetNewPassword && !showLogin && !showCheckYourEmail && (
              <div className="row bg-color me-0 ms-0">
                <div className="col-lg-7 p-0">
                  <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                    <div className="loginlogo-div">
                      <img
                        className="loginlogo"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Websitelogo/netpurti_logo.png"
                        }
                        alt="social_img"
                      />
                    </div>

                    <div className="col-xxl-8  col-xl-8 col-lg-8 col-md-9 ">
                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home</p>
                        </Link>
                      </div>

                      <div className="heading-holder">
                        <h4>Reset Your Password</h4>
                        <p>
                          You will receive an otp to verify email.
                        </p>
                      </div>

                      <div className="log_in_form">
                        <Form >
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="Enter Email"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value: RegxExpression.email,
                                  message: "Invalid email address",
                                },
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email,
                                "is-valid": getValues("email"),
                              })}
                            />
                            {errors?.email && (
                              <sup className="text-danger">
                                {errors?.email?.message}
                              </sup>
                            )}
                          </Form.Group>

                          <EmailOtpModal
                            show={modalShow3}
                            seconds={seconds}
                            otpErr={otpErr}
                            setSeconds={setSeconds}
                            verifyOTP={verifyOTP}
                            handleSendOtp={() => handleSendOtp("email", "resend")}
                            onHide={() => {
                              setModalShow3(false);
                              setOtpErr("");
                            }}
                          />

                          {!otpLoader && (
                            <div className="btn-holder">
                              <Buttons
                                onClick={handleSendOtp}
                                className="SubmitBtn"
                                text="Send OTP"
                              />
                            </div>
                          )}

                        </Form>



                        <div className="create_account">
                          {/* On click, set showLogin state to true */}
                          {/* <Link to={"/login"}> */}
                          <span
                            className="cancel-text"
                            onClick={() => setShowLogin(true)}
                          >
                            CANCEL
                          </span>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showCheckYourEmail && <CheckEmail />}
            {showSetNewPassword && <SetNewPassword email={getValues('email')} />}
            {showLogin && <Login />}
          </div>
        </Container>
      </section>
    </>
  );
}

export default ResetPassWord;
