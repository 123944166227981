import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const addWishlist = async (id, seller_id, domain_id) => {
  try {
    return await postData(`/outer-website/wishlist/add`, {
      product_id: id,
      seller_id: seller_id,
      domain_id: domain_id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getWishlist = async (domain_id) => {
  try {
    return await getData(`/outer-website/wishlist/show?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getWishlistProducts = async (domain_id) => {
  try {
    return await getData(`/outer-website/wishlist/show-products?domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
};
