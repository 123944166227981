import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using React Router

import "./Heart_Component.css";
import { Context } from "../../../utils/context";
import { addWishlist } from "../../../utils/apis/Wishlist/Wishlist";

function Heart_Component({ id, getList }) {
  const [heartactive, setheartActive] = useState(false);
  const { sellerId, wishlistData, wishlistContext, signin, sellerDomain } =
    useContext(Context);

  const navigate = useNavigate();
  const handleClickes = async () => {
    console.log(signin);
    if (signin) {
      await addWishlist(id, sellerId, sellerDomain);
      await wishlistContext();
      if (getList) {
        await getList();
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="hearticn">
        <FontAwesomeIcon
          icon={faHeart}
          onClick={handleClickes}
          className={
            wishlistData?.some((item) => item == id)
              ? "gobiheartss"
              : "gobiheart"
          }
        // className={heartactive ? "gobiheartss" : "gobiheart"}
        />
      </div>
    </>
  );
}

export default Heart_Component;

// import React, { useState, useContext, useEffect } from "react";
// import "./Product_Card.css";
// import { Card, CardFooter } from "react-bootstrap";
// import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCartShopping, faHeart } from '@fortawesome/free-solid-svg-icons';

// import { Context } from "../../../utils/context";
// import { ShimmerPostItem } from "react-shimmer-effects";
// import { useNavigate } from 'react-router-dom';
// import { AddToCart } from "../../../utils/apis/addToCart/addToCart";

// import { addWishlist, getWishlist } from "../../../utils/apis/Wishlist/Wishlist";
// function Product_Card({ product_id, price, subText, text, image }) {

//   const [activeHearts, setActiveHearts] = useState(false);

//   const { shimmerLoader, setShimmerLoader, addCartModal, setAddCartModal, wishlistData, wishlistContext } = useContext(Context);
//   const navigate = useNavigate();
//   const { signin } = useContext(Context);
//   const handleHeartClick = async (id) => {
//     setShimmerLoader(true);
//     if (signin) {
//       const res = await addWishlist(id);
//       if (res?.success) {
//         wishlistContext();
//       }
//       setShimmerLoader(false);
//     } else {
//       setShimmerLoader(false);
//       navigate('/login');
//     }

//   };

//   const handleNavigate = async () => {
//     localStorage.setItem('product_id', product_id);
//     navigate('/vendor-page');
//   }

//   return (
//     <>

//       {shimmerLoader ? (
//         <ShimmerPostItem card title cta
//           imageHeight={180}
//         />
//       ) : (
//         <div className="main-product-img-main mt-4 mb-5">
//           <Card>

//             <div className="main-product-main mx-auto" onClick={handleNavigate}>
//               <Card.Img
//                 variant="top"
//                 src={image}
//                 className="product-img"
//                 alt="..."
//               />
//             </div>

//             <div className="add-cart-sec">
//               <div onClick={() => { AddToCart(product_id); setAddCartModal(true) }}>
//                 <p>
//                   <FontAwesomeIcon
//                     icon={faCartShopping}
//                     className="cart-logo me-2 "
//                   />
//                   Add To Cart
//                 </p>
//               </div>

//               <div>
//                 <p onClick={() => handleHeartClick(product_id)}>
//                   <FontAwesomeIcon
//                     icon={faHeart} // Corrected icon usage
//                     className={
//                       wishlistData.some(item => item.product_id === product_id) ? "cart-logo me-2 active" : "cart-logo me-2"
//                     }
//                   />
//                   Added To Wishlist
//                 </p>
//               </div>
//             </div>

//             <Card.Body>
//               <Card.Text className=" text-holder  mt-3">
//                 <h4>{text}</h4>
//                 <span>{subText}</span>

//                 <p className="card-second-line">₹ {price}/-</p>
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </div>
//       )}

//       <SupermarketModal show={addCartModal} onHide={() => setAddCartModal(false)} />
//     </>
//   );
// }

// export default Product_Card;
