import React from "react";
import "./Nav_Pills_Holder.css";
import Nav from "react-bootstrap/Nav";

function Nav_Pills_Holder({ tabs, categoryActive, getProducts }) {
  return (
    <>
      <div className="Nav_Pills_Holder">
        <Nav variant="pills" className="Pills_Holder">
          {tabs?.map((tab, index) => (
            <Nav.Item onClick={() => getProducts(tab?.category_id)}>
              <Nav.Link
                className={categoryActive == tab?.category_id ? "active" : ""}
              >
                {tab?.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
    </>
  );
}

export default Nav_Pills_Holder;
