import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const orderGet = async (domain_id) => {
  try {
    const res = await getData(`/outer-website/order-process/orderget?domain_id=${domain_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserGet = async () => {
  try {
    const res = await getData(`/outer-website/my-profile`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserEdit = async (data) => {
  try {
    const res = await postData(`/outer-website/my-profile`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePassword = async (data) => {
  try {
    const res = await postData(`/outer-website/my-profile/change-password`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const orderReturn = async (data) => {
  try {
    const res = await postData(`/outer-website/order-process/order-return`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleOrderGet = async (id) => {
  try {
    const res = await getData(`/outer-website/order-process/singleorderget/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

