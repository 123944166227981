import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Breadcrumb, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
import Cancle_Reason_Modal from "../../Common_Component/Cancle_Reason_Modal/Cancle_Reason_Modal";
import Common_Button from "../../Common_Component/Buttons/Buttons";
import { useParams } from "react-router-dom";
import { Context } from "../../../utils/context";
import { singleOrderGet, orderReturn } from "../../../utils/apis/myAccount/myAccount";

const OrderReturn = (Props) => {


    const { orderId } = useParams();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);
    const hideModalWithDelay = () => {
        setTimeout(() => {
            window.location.href = "/order-return-history";
            setModalShow(false);
        }, 3000);
    };

    const { IMG_URL } = useContext(Context);


    const [reason, setReason] = useState('');
    const [remark, setRemark] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [error, setError] = useState('');

    const handleSubmit = async () => {
        if (reason) {
            const data = {
                order_id: orderId,
            }
            if (reason) {
                data.reason = reason;
            }
            if (remark) {
                data.remark = remark;
            }
            const res = await orderReturn(data);
            if (res?.success) {
                setModalShow(true);
                setTimeout(() => {
                    setModalShow(false);
                    // props.onHide();
                    navigate("/my-account");
                }, 3000);
            } else {
                setErrorMessage(res?.message?.errors?.reason?.msg);
            }
        } else {
            setError('Select Reason for cancellation');
        }
    }

    const [order, setOrder] = useState([]);
    const getOrder = async () => {
        const res = await singleOrderGet(orderId);
        if (res?.success) {
            setOrder(res?.data);
        }
    }

    const {signin} = useContext(Context);
    useEffect(() => {
            if(!signin){
                navigate('/');
            }
    },[signin]);
    
    useEffect(() => {
        getOrder();
    }, []);


    return (
        <>
            <div className="Order_Cancle my-account my-account">
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={"/"}>Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to={"/my-account"}>My account</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link>Order Return</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="product-section-div">
                        <div className="product-div">
                            <div className="row">
                                {/*    <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                                    <div className="product">
                                        <img
                                            className="img-set"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Image/My_Profile/cabbege.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>*/}


                                {order?.o_w_orders_details?.map((detail, detailIndex) => (
                                    <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4" >

                                        <div className="product">
                                            <img
                                                className="img-set"
                                                src={IMG_URL + detail?.s_product_variant_attribute_detail?.thumbnail}
                                                alt=""
                                            />
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="text-holder">
                            <p>Order ID : {order?.invoice_number}</p>
                            <h4>{order?.o_w_orders_details?.length} Pc</h4>
                            {/* <span>(Approx. 600 -1000 gm)</span> */}
                            <h4>₹ {order?.amount}</h4>
                        </div>
                    </div>

                    <div
                        className="Cancellation"
                        onClick={() => setModalCanleReasonShow(true)}
                    >
                        <p>
                            Select a reason for returning{" "}
                            <FontAwesomeIcon
                                className="ms-2"
                                icon="fa-solid fa-angle-right"
                            />
                            <span style={{ color: 'red' }}>{errorMessage}</span>
                        </p>
                    </div>

                    <div className="Cancellation  Cancellation-reason">
                        <p>
                            Could you tell us a reason for returning
                            <span> (optional)</span>
                        </p>

                        <Form>
                            <Form.Control
                                as="textarea"
                                role="1"
                                placeholder="Type a reason"
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Form>

                        <div className="btn-holder">
                            <Link to={"/my-account"} >
                                <Common_Button
                                    text="Cancel"
                                    className='cancel-btn'
                                />
                            </Link>
                            <Common_Button
                                //  onClick={() => { setModalShow(true); hideModalWithDelay() }}
                                text="Submit"
                                className='submit-btn'
                                onClick={handleSubmit}
                            />
                        </div>
                        <div className="btn-holder">
                            <sub className="text text-danger">{error}</sub>
                        </div>
                    </div>
                </Container>
            </div>


            <Successfull_Modal
                message=" Order scheduled to return successfully! "
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <Cancle_Reason_Modal
                reason={reason}
                setReason={setReason}
                setError={setError}
                headername=" Select a reason for returingn"
                show={modalCanleReasonShow}
                onHide={() => setModalCanleReasonShow(false)}
            />
        </>
    )
}

export default OrderReturn