import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

const Payment_information = ({ handlePrevious, handleNext, addressSelect, paymentMethod, setPaymentMethod }) => {



    const handleMethod = async (value) => {
        await setPaymentMethod(value);
    }
    return (
        <>
            <section className='stepp-inner-main'>

                <div className='address-div'>
                    <div className='row mt-5'>
                        <div className='col-lg-12 mb-3'>
                            <div className='addd-card'>
                                <div className='row'>
                                    <div className='col-10'>
                                        <div className='card-flex'>
                                            <div className='loc-img-div'>
                                                <img className='map-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/map.png'} />
                                                <img className='loc-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/location.png'} />
                                            </div>
                                            <div className='cont-div'>
                                                <h6 className='hom'>{addressSelect?.address_type}</h6>
                                                <p className='name'>{addressSelect?.f_name} {addressSelect?.l_name}</p>
                                                <p className='adddd'>{addressSelect?.address},{addressSelect?.land_mark},{addressSelect?.city?.name},{addressSelect?.state?.name} - {addressSelect?.pincode?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className='payment-optdiv'>
                    <h6 className='chjvvef'>Choose Payment Option</h6>
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <div className='payment-card'>
                                <div className='row mb-5'>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <Form.Check // prettier-ignore
                                            type="radio"
                                            name="method"
                                            label="Cash on delivery"
                                            value="cod"
                                            defaultChecked={paymentMethod === 'cod' || true}
                                            onClick={() => handleMethod('cod')}
                                        />
                                    </div>
                                    <div className='col-5'>
                                        <Form.Check // prettier-ignore
                                            type="radio"
                                            name="method"
                                            label="Online"
                                            value="online"
                                            defaultChecked={paymentMethod === 'online'}
                                            onClick={() => handleMethod('online')}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <h6 className='paywithcredit'>Pay with credit card</h6>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <div className='d-flex'>
                                            <Form.Check
                                                type="radio"
                                                name="method"
                                                value="visa"
                                                defaultChecked={paymentMethod === 'visa'}
                                                onClick={() => handleMethod('visa')}
                                            />
                                            <img className='paymopttimg' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/visa.png'} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <div className='d-flex'>
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                name="method"
                                                value="master"
                                                defaultChecked={paymentMethod === 'master'}
                                                onClick={() => handleMethod('master')}
                                            />
                                            <img className='paymopttimg' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/mastercard.png'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-12 mb-3'>
                            <div className='payment-card'>

                                <div className='row'>
                                    <h6 className='paywithcredit'>Pay with UPI</h6>
                                    <div className='col-lg-5 col-md-5 col-6 mb-4'>
                                        <div className='d-flex'>
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                name="method"
                                                value="gpay"
                                                defaultChecked={paymentMethod === 'gpay'}
                                                onClick={() => handleMethod('gpay')}
                                            />
                                            <img className='paymopttimg' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/gpay.png'} />
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-5 col-6 mb-4'>
                                        <div className='d-flex'>
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                name="method"
                                                value="phonepe"
                                                defaultChecked={paymentMethod === 'phonepe'}
                                                onClick={() => handleMethod('phonepe')}
                                            />
                                            <img className='paymopttimg' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/phonepay.png'} />
                                        </div>
                                    </div>
                                    <div className='col-5  '>
                                        <div className='d-flex'>
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                name="method"
                                                value="paytm"
                                                defaultChecked={paymentMethod === 'paytm'}
                                                onClick={() => handleMethod('paytm')}
                                            />
                                            <img className='paymopttimg' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/paytm.png'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="save-btn-div mt-5">
                    <button className="back-btn" onClick={handlePrevious}> BACK</button>
                    <button className="continue-btn" onClick={handleNext}> NEXT </button>
                </div>
            </section>
        </>
    )
}

export default Payment_information