import React, { useContext, useState, useEffect } from "react";
import './BlogDetails.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Importing useParams hook
import { Context } from "../../../utils/context";
const BlogDetails = () => {

    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { id } = useParams();
    const { getData, IMG_URL } = useContext(Context);
    const [blogData, setBlogData] = useState(null);

    useEffect(() => {
        console.log({ id });
        const getDataAllLatest = async () => {
            try {
                const response = await getData(`/outer-website/blogs/${id}`);
                setBlogData(response?.data);
            } catch (error) {
                console.error("Error fetching blog data:", error);
            }
        };
        getDataAllLatest();
        console.log(blogData);
    }, [id]);
    return (
        <>
            <section className='blog-details'>
                <div className='container'>
                    <div className='row'>
                        <Breadcrumb>
                            <Breadcrumb.Item ><Link to="/blog">Blog</Link></Breadcrumb.Item>
                            <Breadcrumb.Item active>{blogData?.heading}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='col-xxl-4 col-xl-4 col-lg-5 '>
                            <div className='image-holder'>
                                <img
                                    src={IMG_URL + blogData?.image}
                                    className="img-details" />
                            </div>
                        </div>
                        <div className='col-xxl-8 col-xl-8 col-lg-7 '>
                            <div className='text-holder'>
                                <p>{blogData?.createdAt && (
                                    <>
                                        {new Date(blogData?.createdAt).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: 'short', // Use 'short' to display month name abbreviated like 'May'
                                            year: 'numeric'
                                        })}
                                    </>
                                )}</p>
                                <h2>{blogData?.name}</h2>
                                <p>{htmlToReactParser.parse(blogData?.description)}</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            {htmlToReactParser.parse(blogData?.long_description)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetails