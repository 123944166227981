import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../HomePage/HomePage";
import Login from "../Login/Login";
import Register from "../Login/Register/Register";
import ResetPassWord from "../Login/ResetPassWord/ResetPassWord";
import SetNewPassword from "../Login/SetNewPassword/SetNewPassword";
import CheckEmail from "../Login/CheckEmail/CheckEmail";
import ProductDescription from "../ProductDescription/ProductDescription";
import Payment_checkout from "../payment-checkout-form/Payment_checkout";
import Product_Cart_Inner from "../Product_Cart_Inner/Product_Cart_Inner";
import Our_Products_Page from "../Our_Products_Page/Our_Products_Page";
import WishList from "../WishList/WishList";
import AboutUs from "../About-us/AboutUs";
import ContactUs from "../contact-us/ContactUs";
import Blog from "../Blog/Blog";
import Faq from "../Faq/Faq";
import BlogDetails from "../Blog/Blog-details/BlogDetails";
import MyAccount from "../MyAccount/MyAccount";
import OrderHistory from "../MyAccount/OrderHistory/OrderHistory";
import AccountInfo from "../MyAccount/AccountInfo/AccountInfo";
import ChangePassword from "../MyAccount/ChangePassword/ChangePassword";
import Shop_Now from "../Product_Cart_Inner/Shop_Now/Shop_Now";
import OrderReturn from "../MyAccount/Order-return/OrderReturn";
import AllProductList from "../AllProductList/AllProductList";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/resetpassword" element={<ResetPassWord />}></Route>
        <Route path="/setnewpassword" element={<SetNewPassword />}></Route>
        <Route path="/checkemail" element={<CheckEmail />}></Route>
        <Route
          path="/productdescription/:id"
          element={<ProductDescription />}
        ></Route>
        <Route path="/checkout-form" element={<Payment_checkout />}></Route>
        <Route path="/shop-now" element={<Shop_Now />} />
        <Route
          path="/product-cart-inner"
          element={<Product_Cart_Inner />}
        ></Route>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/information" element={<AccountInfo />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route
          path="/our_products_page/:category"
          element={<Our_Products_Page />}
        ></Route>
        <Route path="/wishlist" element={<WishList />}></Route>

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/order-return/:orderId" element={< OrderReturn />} />
        <Route path="/allproductlist" element={<AllProductList />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
