import React from "react";
import "./Select_Drop.css";
import Form from "react-bootstrap/Form";

function Select_Drop({ labelText, optionsSelect, setCategoryId }) {


  const handleSelection = async (e) => {
    console.log(e.target.value);
    localStorage.setItem("web2_category_id", e.target.value);
  }
  return (
    <>
      <div className="Select_Drop">
        <div className="label_holder">
          <p className="label_text">{labelText}</p>
        </div>
        <div className="option_select">
          <Form.Select aria-label="Default select example"  onChange={(e) => handleSelection(e)}>
            {optionsSelect?.map((option) => (
              <option
                key={option?.category_id}
                value={option?.category_id}
              >
                {option?.name}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default Select_Drop;
