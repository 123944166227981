import {
  postData
} from "../../api";

export const userRegister = async (data) => {
  try {
    const res = await postData(`/outer-website/auth/register`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userLogin = async (data) => {
  try {
    const res = await postData(`/outer-website/auth/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
