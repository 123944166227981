import React, { useState } from "react";
import "./Incre_Decre_btn.css";
import {
  CartQuantity,
  RemoveToCart,
} from "../../../utils/apis/addToCart/addToCart";

function Incre_Decre_btn(props) {
  const [counter, setCounter] = useState(props.quantity);
  const [buttonStatus, setButtonStatus] = useState("true");

  async function increment(counter) {
    counter++;
    setButtonStatus("");
    await CartQuantity(props.id, "add");
    setCounter(counter);
    props.cartGet();
  }
  async function decrement(counter) {
    counter--;
    if (counter == 0) {
      await RemoveToCart(props.id);
    } else {
      await CartQuantity(props.id, "minus");
    }
    setCounter(counter);
    props.cartGet();
    if (counter < 1) {
      setButtonStatus("true");
    }
  }
  return (
    <>
      <div className="Incre_Decre_btn scale-up-hor-right">
        <div>
          <button
            className="counter_btn minus "
            disabled={buttonStatus}
            onClick={() => decrement(counter)}
          >
            {" "}
            -{" "}
          </button>
        </div>

        <div>
          <span className="subText">{counter}</span>
        </div>

        <div>
          <button
            className="counter_btn plus "
            onClick={() => increment(counter)}
          >
            +
          </button>
        </div>
      </div>
    </>
  );
}

export default Incre_Decre_btn;
