import React, { useState, useEffect, useContext } from "react";
import './AboutBanner.css'
import { Context } from "../../../utils/context";
const AboutBanner = () => {
    const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);

    const [data, setData] = useState();
    const getBanner = async () => {
        const res = await getData(`/outer-website/about-us/banner?domain_id=${sellerDomain}`);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getBanner();

    }, [sellerId]);

    return (
        <>
            <section className='about-banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mx-auto mt-3'>
                            <img src={IMG_URL + data?.image} className="about-banner" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutBanner